include('_storage.js');
include('_state.js');
include('_forms.js');
include('_snack.js');

(function ($) {
  // jQuery 3+
  $(function () {
    /**
     * Variables
     *************************************************/
    const $html = $('html');
    const $body = $('body');
    const $header = $('header');
    const $contact = $('#contact');
    const $contactTrigger = $('#contact-trigger');
    const $scrollToTop = $('#scroll-to-top');

    /**
     * Fancybox Defaults
     *************************************************/
    $.fancybox.defaults.loop = false;
    $.fancybox.defaults.gutter = 32;
    $.fancybox.defaults.keyboard = true;
    $.fancybox.defaults.arrows = true;
    $.fancybox.defaults.infobar = true;
    $.fancybox.defaults.smallBtn = false;
    $.fancybox.defaults.buttons = [];
    $.fancybox.defaults.protect = true;
    // animation
    $.fancybox.defaults.animationEffect = 'fade';
    $.fancybox.defaults.animationDuration = 375;
    $.fancybox.defaults.transitionEffect = 'slide';
    $.fancybox.defaults.transitionDuration = 300;
    // modules
    $.fancybox.defaults.hash = false;
    $.fancybox.defaults.onInit = function () {
      matildaState.save();
    };

    /**
     * Misc
     *************************************************/

    // Ready Body Class
    $body.addClass('ready');

    // Scroll Body Class
    let windowScrolled = 0;

    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        // prevent multiple runs
        if (windowScrolled === 0) {
          windowScrolled = 1;
          $body.addClass('scrolled');
        }
      } else {
        // prevent multiple runs
        if (windowScrolled === 1) {
          windowScrolled = 0;
          $body.removeClass('scrolled');
        }
      }
    });

    // Scroll To Top
    $scrollToTop.on('click', function () {
      $html.velocity('scroll', {
        offset: 0,
        duration: 375,
        mobileHA: false,
      });
      return false;
    });

    // Scroll To Hash on Page Load
    setTimeout(function () {
      if (location.hash) {
        const target = location.hash;
        let targetOffset = $header.outerHeight();

        // reset scroll position
        window.scrollTo(0, 0);

        // add contact trigger to the offset
        if ($contact.is(':visible')) {
          targetOffset += $contactTrigger.outerHeight();
        }

        // scroll
        $(target).velocity('scroll', {
          offset: -targetOffset,
          duration: 375,
          mobileHA: false,
        });
      }
    }, 1);

    // Scroll To
    $('.scroll-to').on('click', function () {
      const target = $(this).attr('href');
      let targetOffset = $header.outerHeight();

      // add font size of the target to the offset
      if (!$(this).hasClass('no-offset')) {
        targetOffset += Number.parseInt($(target).css('font-size'));
      }

      // add contact trigger to the offset
      if ($contact.is(':visible')) {
        targetOffset += $contactTrigger.outerHeight();
      }

      // scroll
      $(target).velocity('scroll', {
        offset: -targetOffset,
        duration: 375,
        mobileHA: false,
      });
      return false;
    });
  }); // ready

  // jQuery 3+
  $(window).on('load', function () {
    /**
     * Variables
     *************************************************/
    const $body = $('body');

    /**
     * Misc
     *************************************************/
    // Loaded Body Class
    $body.addClass('loaded');
  }); // load
})(jQuery);
